import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { ReactComponent as LogoXepelin } from "../../assets/logo-blanco.svg";
import PropTypes from "prop-types";

import validate from "validate.js";
import constraints from "../../data/constraints";
import authentication from "../../services/authentication";
import nubesBackground from '../../assets/nubesBackgroundLogin.svg';
import Mail from "../../icons/Mail";
import Lock2 from "../../icons/Lock2.js"
import EyeClose from "../../icons/EyeClose.js"
import EyeOpen from "../../icons/EyeOpen.js"
import AlertForm from '../../elements/AlertForm';

import {
  InputAdornment,
  Box,
  IconButton
} from '@material-ui/core';

const useStyles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
      overflowY: "hidden", // hide vertical
      overflowX: "hidden" // hide horizontal
    }
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    "& .MuiOutlinedInput-root": {
      fontFamily: "Muli",
      borderColor: "#CECAD9",
      "&:hover": {
        borderColor: "#7300FF"
      },
      borderRadius: 31,
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#7300FF"
      }
    },
    "& label.Mui-focused": {
      color: "#7300FF"
    },
    "& .MuiOutlinedInput-input": {
      padding: "14.5px 15px"
    }
  },
  submit: {
    background: "#7300FF",
    "&:hover": {
      background: "#4d00ab"
    },
    fontFamily: "Muli",
    borderRadius: 50,
    margin: theme.spacing(3, 0, 2)
  },
  paddingTop: { 
    paddingTop: theme.spacing(12), 
    align: "center"
  },
  loginTitle: {
    fontWeight: "bold",
    fontSize: 30,
    color: "#575360"
  },
  errorMessage: {
    width: "100%",
    padding: "10px 10px 0px",
    color: "red"
  }, 

  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 17,
    minWidth: 414,
    height: 455,
    background: `transparent url(${nubesBackground})  top no-repeat`,
    backgroundColor: '#FFFF',
  },
  logoTopBar: {
    display: 'flex',
    justifyContent: 'center',
    padding: 21,
    paddingBottom: 107,
  },
  backgroundImage: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    flexBasis: '85%',
    alignSelf: 'center',
    borderRadius: 17,
    boxShadow: '0px 3px 10px #00000015',
  },
  textField: {
    borderRadius: 50 ,
  },
  formContainer: {
    width: '100%',
    padding: '43px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    color: '#FFFF',
  },
  subtitle: {
    color: '#FFFF',
  }
});

const initialState = {
  performingAction: false,
  emailAddress: "",
  password: "",
  errors: null,
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  showPassword = true;


  tooglePasswordVisibility = (event) => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  handlePasswordChange = (event) => {
    const password = event.target.value;

    this.setState({
      password: password,
    });
  };

  getSignInButton = () => {
    const { emailAddress, password, performingAction } = this.state;

    const { classes } = this.props;

    if (emailAddress && !password) {

      return (
        <Button
          variant="contained"
          color="primary"
          disabled={!emailAddress || performingAction}
          onClick={() => this.sendSignInLinkToEmail()}
          class={classes.submit}
        >
          Send sign-in link
        </Button>
      );
    }

    return (
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={!emailAddress || performingAction}
        onClick={() => this.signIn()}
        style={{ borderRadius: 50 }}
      >
        Ingresar
      </Button>
    );
  };

  resetPassword = () => {
    const { emailAddress } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          errors: null,
        },
        () => {
          this.setState(
            {
              performingAction: true,
            },
            () => {
              authentication
                .resetPassword(emailAddress)
                .then((value) => {
                  this.props.openSnackbar(
                    `Sent password reset e-mail to ${emailAddress}`
                  );
                })
                .catch((reason) => {
                  const code = reason.code;
                  const message = reason.message;

                  switch (code) {
                    case "auth/invalid-email":
                    case "auth/missing-android-pkg-name":
                    case "auth/missing-continue-uri":
                    case "auth/missing-ios-bundle-id":
                    case "auth/invalid-continue-uri":
                    case "auth/unauthorized-continue-uri":
                    case "auth/user-not-found":
                      this.props.openSnackbar(message);
                      return;

                    default:
                      this.props.openSnackbar(message);
                      return;
                  }
                })
                .finally(() => {
                  this.setState({
                    performingAction: false,
                  });
                });
            }
          );
        }
      );
    }
  };

  signIn = () => {
    const { emailAddress, password } = this.state;

    const errors = validate(
      {
        emailAddress: emailAddress,
        password: password,
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
      }
    );

    if (errors) {
      this.setState({
        errors: errors,
      });
    } else {
      this.setState(
        {
          performingAction: true,
          errors: null,
        },
        () => {
          authentication
            .signIn(emailAddress, password)
            /*.then((user) => {
              this.props.dialogProps.onClose(() => {
                const displayName = user.displayName;
                const emailAddress = user.email;

                this.props.onClose(
                  `Signed in as ${displayName || emailAddress}`
                );
              });
            })*/
            .catch((reason) => {
              const code = reason.code;
              const message = reason.message;

              switch (code) {
                case "auth/invalid-email":
                case "auth/user-disabled":
                case "auth/user-not-found":
                case "auth/wrong-password":
                  this.props.openSnackbar(message);
                  return;

                default:
                  this.props.openSnackbar(message);
                  return;
              }
            })
            .finally(() => {
              this.setState({
                performingAction: false,
              });
            });
        }
      );
    }
  };

  handleExited = () => {
    this.setState(initialState);
  };

  handleKeyPress = (event) => {
    const { emailAddress, password } = this.state;

    if (!emailAddress && !password) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === "Enter") {
      if (emailAddress && !password) {
        this.sendSignInLinkToEmail();
      } else {
        this.signIn();
      }
    }
  };
  render() {
    const { classes } = this.props;

    const { performingAction, emailAddress, password, errors } = this.state;
    return (
          <Grid
          container
          direction="row"
          alignContent="flex-start"
          justify="center"
          className={classes.mainContainer}
        >
          <Grid item xs={12} className={classes.logoTopBar}>
            <LogoXepelin height="50px" />
          </Grid>
          <Grid item xs={12} className={classes.backgroundImage}>
            <Grid
              container
              direction="row"
              justify="center"
            >
                <Paper className={classes.paper} >
                <form className={classes.formContainer} onSubmit={this.signIn}>
                <Typography
                  variant="h5"
                  align="center"
                  color="textPrimary"
                  className={classes.title}
                  gutterBottom
                >
                  Iniciar Sesión
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textPrimary"
                  className={classes.subtitle}
                >
                  Ingresa los datos de tu cuenta.
                </Typography>
                <Grid item xs={12} className={classes.paddingTop} />
                &nbsp;
                  <TextField
                      className="inputRounded"
                      marginTop="40px"
                      variant="outlined"
                      //margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      onChange={this.handleEmailAddressChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Box color="text.secondary">
                              <Mail fontSize="small" color="inherit" />
                            </Box>
                          </InputAdornment>
                        ),
                      }}

                  />
                  <TextField
                    variant="outlined"
                    className="inputRounded"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleInputChange}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box color="text.secondary">
                            <Lock2 fontSize="small" color="inherit" />
                          </Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={this.tooglePasswordVisibility}>
                            <Box color="text.secondary">
                              {this.state.showPassword ? (
                                <EyeClose fontSize="small" color="inherit" />
                              ) : (
                                <EyeOpen fontSize="small" color="inherit" />
                              )}
                            </Box>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                {errors && (
                  <AlertForm
                    message="Las credenciales no coinciden"
                    variant="error"
                  />
                )}

                {this.getSignInButton()}

                </form>

                </Paper>
            </Grid>
          </Grid>
        </Grid>
    );
  }
}

Login.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

  // Custom Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(useStyles)(Login);
