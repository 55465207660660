import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import { auth, firestore } from '../../firebase';

const useStyles = {
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: 20,
    minHeight: '75vh',
    borderRadius: 17,
    padding: '20px 0px',
    border: '1px solid #E7E5EC',
  },
};

class DataStudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      urls: {}, 
      perfiles: [], 
      perfil: '', 
      options: ['originacion', 'concentraciones', 'rendimiento', 'covenants'],
      originacion: '', 
      concentraciones: '', 
      rendimiento: '', 
      covenant: ''
    }
  }

  componentDidMount() {
    const currentUser = auth.currentUser;
    const uid = currentUser.uid;

    const perfiles = currentUser["perfiles"];
    const perfil = currentUser["perfilSelected"];

    var urls = {}
    firestore.collection("users/" + uid + "/dataStudio"+ perfil)
    firestore
    .collection("users")
    .doc(uid)
    .collection("dataStudio")
    .get()
    .then(snapshot => {
      snapshot.forEach(doc =>{
        if (doc.data().name === perfil) {
          urls = doc.data()
          this.setState((state) => {
            return {
              perfiles: perfiles, 
              urls: urls, 
              perfil: perfil
          };
        })
        }
      })
    })
  }

    render () {
    if (auth.currentUser) {
      const currentUser = auth.currentUser;
      const uid = currentUser.uid;
  
      const perfiles = currentUser["perfiles"];
      const perfil = currentUser["perfilSelected"];
  
      var urls = {}  
      firestore.collection("users/" + uid + "/dataStudio"+ perfil)
      firestore
      .collection("users")
      .doc(uid)
      .collection("dataStudio")
      .get()
      .then(snapshot => {
        snapshot.forEach(doc =>{
          if (doc.data().name === perfil) {
            urls = doc.data()
            this.setState((state) => {
              return {
                perfiles: perfiles, 
                urls: urls, 
                perfil: perfil
            };
          })
          }
        })
      })
    }

      var aux = this.state;
      /*var str = JSON.stringify(aux)
      console. log('state',  aux)
      console. log('finmas SpA 1 type:',  typeof(aux.urls['Finmas SpA']))
      console. log('finmas SpA 1:',  aux.urls['Finmas SpA'])
      */

      
      try {
        //console. log('finmas SpA:',  typeof(aux.urls['Finmas SpA']['covenants']))
        var options = this.state.options;
        var s = this.props.selected
        
        var select = options[s]
        var list = aux.urls
        var dash = list[select]

        return (
            <iframe
            overflow-y="hidden"
            title="reporteria-iframe"
            allowFullScreen="true"
            frameBorder="0"
            src={dash}
            width="100%"
            height={window.innerHeight + "px"}
            position="absolute"
          />

          )

      } catch (e) {
        console.error(e)
      }
      return (
          <></>
      );
    }
};

DataStudio.propTypes = {
  businessId: PropTypes.number.isRequired,
};

export default compose(
  withStyles(useStyles),
)(DataStudio);

