import {
  STATUS_IN_REVIEW,
  STATUS_REVIEWED,
  STATUS_APPEALED,
  STATUS_APPROVED_FUND,
  STATUS_TO_DEPOSIT,
  STATUS_POST_VERIFIED,
  STATUS_VERIFIED,
  STATUS_CEDE,
  STATUS_ACTIVE,
  STATUS_PAID,
  STATUS_REPLACED,
  STATUS_DEFAULT,
  STATUS_SOFT_DELINQUENCY,
  STATUS_HARD_DELINQUENCY,
  STATUS_DELINQUENT,
  STATUS_HARD_COLLECTION,
  STATUS_COMPLETE,
  STATUS_REJECTED,
  STATUS_APPROVED,
  STATUS_RENEGOTIATION,
  STATUS_PV_APPROVED,
} from './Constants';
import { CHIP_VARIANTS } from './theme/otherColors';

// eslint-disable-next-line import/prefer-default-export
export const lightenColor = (hex, percent) => {
  // strip the leading # if it's there

  let color = hex.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (color.length === 3) {
    color = color.replace(/(.)/g, '$1$1');
  }

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);
  const bitLen = 2 ** 8;
  const rHex = Math.trunc(bitLen + r + ((bitLen - r) * percent) / 100)
    .toString(16)
    .substr(1);
  const gHex = Math.trunc(bitLen + g + ((bitLen - g) * percent) / 100)
    .toString(16)
    .substr(1);
  const bHex = Math.trunc(bitLen + b + ((bitLen - b) * percent) / 100)
    .toString(16)
    .substr(1);
  return `#${rHex}${gHex}${bHex}`;
};

const ORDER = 'order';
const INVOICE = 'invoice';
export const getVariantOrColor = (entityType, status, getColor = false) => {
  let variant;
  switch (entityType) {
    case INVOICE:
      switch (status) {
        case STATUS_IN_REVIEW:
        case STATUS_REVIEWED:
        case STATUS_APPEALED:
          variant = 'yellow';
          break;
        case STATUS_APPROVED:
        case STATUS_APPROVED_FUND:
          variant = 'lightgreen';
          break;
        case STATUS_TO_DEPOSIT:
        case STATUS_POST_VERIFIED:
        case STATUS_VERIFIED:
        case STATUS_CEDE:
          variant = 'green';
          break;
        case STATUS_ACTIVE:
        case STATUS_PAID:
        case STATUS_REPLACED:
          variant = 'lightblue';
          break;
        case STATUS_DEFAULT:
        case STATUS_SOFT_DELINQUENCY:
        case STATUS_HARD_DELINQUENCY:
        case STATUS_DELINQUENT:
        case STATUS_HARD_COLLECTION:
          variant = 'blue';
          break;
        case STATUS_COMPLETE:
          variant = 'purple';
          break;
        case STATUS_REJECTED:
          variant = 'red';
          break;
        default:
          variant = 'default';
          break;
      }
      break;
    case ORDER:
      switch (status) {
        case STATUS_IN_REVIEW:
        case STATUS_REVIEWED:
        case STATUS_APPEALED:
          variant = 'yellow';
          break;
        case STATUS_APPROVED:
        case STATUS_APPROVED_FUND:
          variant = 'lightgreen';
          break;
        case STATUS_TO_DEPOSIT:
        case STATUS_POST_VERIFIED:
        case STATUS_VERIFIED:
        case STATUS_PV_APPROVED:
          variant = 'green';
          break;
        case STATUS_ACTIVE:
        case STATUS_PAID:
        case STATUS_RENEGOTIATION:
          variant = 'lightblue';
          break;
        case STATUS_DEFAULT:
          variant = 'blue';
          break;
        case STATUS_COMPLETE:
          variant = 'purple';
          break;
        case STATUS_REJECTED:
          variant = 'red';
          break;
        default:
          variant = 'default';
          break;
      }
      break;
    default:
      variant = 'default';
      break;
  }

  return getColor ? CHIP_VARIANTS[variant].color : variant;
};
