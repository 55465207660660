import React, { Component } from "react";

import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { auth } from "../../firebase";

import authentication from "../../services/authentication";

import HomeSideBar from "../HomeSideBar";
import Container from "../Container";
import DataStudio from "../DataStudio";

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      dashboards: [],
      showDrawer: false, 
      sideBarOptions: [], 
      open: false, 
      selected: 0,
      resData: '',
      isLoading: true,
    };
    this.handleClick.bind(this)
  }

  signInWithEmailLink = () => {
    const { user } = this.props;

    if (user) {
      return;
    }

    const emailLink = window.location.href;

    if (!emailLink) {
      return;
    }

    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");

      if (!emailAddress) {
        this.props.history.push("/");

        return;
      }

      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          this.props.openSnackbar(
            `Signed in as ${displayName || emailAddress}`
          );
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;

          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              this.props.openSnackbar(message);
              break;

            default:
              this.props.openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          this.props.history.push("/");
        });
    }
  };

  handleClick = (index) => {
    this.setState((state) => {
      return {
        selected: index,
      }
    });
  };

  render() {
    const { user } = this.props;

    if (user) {
      return (
        <Container gridTemplate="min-content 1fr" gridGap="0px" min-height="100%">
          <Container sticky stickyTop={50} zIndex={0}>
            <HomeSideBar 
            // completar aqui 
            handleClick = {this.handleClick} 
            />
          </Container>
          < DataStudio
            selected={this.state.selected}/>
        </Container>
      );
    }
  }

  componentDidMount() {
    this.signInWithEmailLink();
  }
}

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(HomePage);
