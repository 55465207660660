import { Box, Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import React from 'react';
import { lightenColor } from '../helpers/Colors';

const useStyles = theme => ({
  root: {
    margin: '0px 0px 15px',
    width: '100%',
  },
  alertStart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    width: 40,
    background: props => theme.palette[props.variant].main,
  },
  alertEnd: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    height: '100%',
    width: 'calc(100% - 40px)',
    padding: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    background: props => lightenColor(theme.palette[props.variant].main, 85),
  },
  icon: {
    color: '#FFF',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const AlertForm = props => {
  const classes = useStyles(props);
  const { message, messageComponent, variant } = props;
  const getIconAlert = () => {
    switch (variant) {
      case 'error':
        return <CancelIcon className={classes.icon} />;
      case 'info':
        return <InfoIcon className={classes.icon} />;
      case 'warning':
        return <ErrorIcon className={classes.icon} />;
      case 'success':
      default:
        return <CheckCircleIcon className={classes.icon} />;
    }
  };

  return (
    <Grid container direction="row" justify="center" className={classes.root}>
      <Grid item className={classes.alertStart}>
        {getIconAlert()}
      </Grid>
      <Grid item className={classes.alertEnd}>
        <div className={classes.message}>
          {messageComponent || (
            <Typography
              align="left"
              variant="body2"
              color="textPrimary"
              component="div"
            >
              <Box fontWeight="fontWeightBold">{message}</Box>
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

AlertForm.defaultProps = {
  message: null,
  messageComponent: null,
};

AlertForm.propTypes = {
  message: PropTypes.string,
  messageComponent: PropTypes.element,
  variant: PropTypes.oneOf(['error', 'info', 'warning', 'success']).isRequired,
};

export default AlertForm;
