import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

//material ui
import Hidden from '@material-ui/core/Hidden';

//icons
import Concentraciones from './icons/concentraciones.js'
import Originacion from './icons/originacion.js'
import Rendimiento from './icons/rendimiento.js'
import Convenios from './icons/convenios.js'
import Container from './elements/Container';
import SideBarNavigationButton from './elements/SideBarNavigationButton';

const useStyles = {
  root: {
    flexGrow: 1,
  },
  leftContainer: {
    padding: '55px 20px 20px 20px',
    minHeight: 'calc(100vh - 50px)',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 7px #00000014',
    fontSize: '1.3333333333333333rem',
    fontFamily: 'Muli',
  },
  button: {
    padding: '100px 100px',
  }
};

class HomeSideBar extends Component {  
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0
    };
  }

  handleListItemClick = (event, index) => {
    this.setState({
      selectedIndex: index
    });
  };

  render() {
    const { className, classes, onSidebarOpen, ...rest } = this.props;
    const { selectedIndex } = this.state;
    return (
    <Hidden xsDown>
      <Container
        gridGap={10}
        padding="10px"
        sticky
        stickyTop={50}
        className={useStyles.leftContainer}
      >

        <SideBarNavigationButton
          icon={Originacion}
          collapsed
          // completar aqui
          onClick={(event) => {
            this.props.handleClick(0);
            this.handleListItemClick(event, 0)}
          }
          active={selectedIndex === 0}
          className={useStyles.button}
          withLabel={true}// {isSidebarOpen}
          style={{backgroundColor : "grey"}}
        >
          Originación y Performance
        </SideBarNavigationButton>

        <SideBarNavigationButton
          icon={Concentraciones}
          collapsed
          onClick={(event) => {
            this.props.handleClick(1);
            this.handleListItemClick(event, 1)}
          } 
          active={selectedIndex === 1}
          //onClick={() => handleButtonClick('/cesionariofinal')}
          //active={location.pathname.includes('/cesionariofinal')}
          withLabel={true}// {isSidebarOpen}
          className={useStyles.button}
        >
          Concentraciones y Vencimientos
        </SideBarNavigationButton>

        <SideBarNavigationButton
          icon={Rendimiento}
          collapsed
          onClick={(event) => {
            this.props.handleClick(2);
            this.handleListItemClick(event,2)}
          } 
          active={selectedIndex === 2} 
          //onClick={() => handleButtonClick('/fechapago')}
          //active={location.pathname.includes('/fechapago')}
          withLabel={true}// {isSidebarOpen}
          className={useStyles.button}
        >
          Rendimiento Cartera
        </SideBarNavigationButton>
        <SideBarNavigationButton
          icon={Convenios}
          collapsed
          onClick={(event) => {
            this.props.handleClick(3);
            this.handleListItemClick(event,3)}
          } 
          active={selectedIndex === 3} 
          //onClick={() => handleButtonClick('/fechapago')}
          //active={location.pathname.includes('/fechapago')}
          withLabel={true}// {isSidebarOpen}
          className={useStyles.button}
        >
          Covenants
        </SideBarNavigationButton>
      </Container>
    </Hidden>
  );
    }
};

export default compose(
  withStyles(useStyles),
)(HomeSideBar);
