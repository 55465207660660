import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Convenios = props => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
        <path d="M20.7773 7.20603L19.7064 6.04298L17.5402 3.67922L16.2527 2.27692C16.1738 2.18991 16.0777 2.12034 15.9705 2.07266C15.8632 2.02498 15.7473 2.00023 15.63 2H3.84546C3.62141 2.00058 3.4067 2.09005 3.24827 2.24883C3.08985 2.40762 3.00058 2.62281 3 2.84736V21.1526C3.00058 21.3772 3.08985 21.5924 3.24827 21.7512C3.4067 21.91 3.62141 21.9994 3.84546 22H20.1545C20.3785 21.9993 20.5932 21.9098 20.7516 21.751C20.91 21.5923 20.9993 21.3771 21 21.1526V7.7798C20.9986 7.56762 20.9193 7.36339 20.7773 7.20603V7.20603ZM19.3091 20.3058H4.69037V3.69417H15.1818V8.1132H19.3091V20.3058Z"/>
        <path d="M7.82318 18.9052C7.66049 18.9051 7.50103 18.8596 7.3626 18.774C7.22416 18.6883 7.11219 18.5658 7.03918 18.4201C6.96617 18.2744 6.93499 18.1112 6.94912 17.9487C6.96324 17.7863 7.02212 17.631 7.11919 17.5001C8.71064 15.3562 10.0319 14.4917 11.2791 14.7825C12.1494 14.9852 12.6163 15.7002 12.9573 16.2224C13.1501 16.5176 13.3679 16.8516 13.5226 16.8765C13.7165 16.9108 14.2636 16.6478 15.0952 15.4753C15.2302 15.2851 15.4351 15.1564 15.6647 15.1176C15.8944 15.0787 16.13 15.1329 16.3198 15.2682C16.5096 15.4034 16.638 15.6088 16.6767 15.8389C16.7155 16.0691 16.6614 16.3052 16.5264 16.4955C15.3914 18.0949 14.3161 18.7867 13.243 18.6144C12.3235 18.4654 11.8416 17.7288 11.4879 17.1861C11.2802 16.8682 11.0658 16.5403 10.8801 16.4971C10.6165 16.4373 9.82631 16.8001 8.52718 18.5502C8.44561 18.6603 8.33945 18.7498 8.21721 18.8114C8.09497 18.8731 7.96003 18.9052 7.82318 18.9052V18.9052Z" />
        <path d="M6.5 6V8H12.5V6H6.5Z" />
        <path d="M6.5 10V12H15.5V10H6.5Z" />
    </SvgIcon>
  );
};

export default Convenios;