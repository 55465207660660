import React, { Component } from "react";

import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";

import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    ButtonGroup,
    Button,
    IconButton,
    Divider,
    Menu,
    MenuItem,
    Link,
} from "@material-ui/core";

import UserAvatar from "../UserAvatar";

import { ReactComponent as LogoBlanco } from '../../assets/logo-horizontal-blanco.svg';


class Bar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: {
                anchorEl: null,
                nombre: ""
            },
        };
    }

    openMenu = (event) => {
        const anchorEl = event.currentTarget;

        this.setState({
            menu: {
                anchorEl,
            },
        });
    };

    closeMenu = () => {
        this.setState({
            menu: {
                anchorEl: null,
            },
        });
    };

    componentDidMount() {
      try {
        this.state.nombre = this.props.user.username
      } catch (e) {
        console.error(e)
      }
    }

    render() {
        // Properties
        const { performingAction, user, userData, roles } = this.props;

        // Events
        const {
            onAboutClick,
            onSettingsClick,
            onSignOutClick,
            onSignUpClick,
            onSignInClick,
        } = this.props;

        const { menu } = this.state;

        const menuItems = [
          {
                name: "Cambiar Perfil",
                onClick: onAboutClick,
            },
          /*
            {
                name: "Profile",
                to: user ? `/user/${user.uid}` : null,
            },
            */
            {
                name: "Settings",
                onClick: onSettingsClick,
            },
            {
                name: "Sign out",
                divide: true,
                onClick: onSignOutClick,
            },
        ];
        
        if (!user) {
          return <> </>
        }
        return (
          <AppBar 
          background= 'transparent linear-gradient(270deg, #6D02FE 0%, #6D02FE 100%) 0% 0% no-repeat padding-box'
          height="56"
          alignContent='center'
          color="primary" 
          position="static"
          >
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Typography color="inherit" variant="h6">
              <Link
                color="inherit"
                component={RouterLink}
                to="/"
                underline="none"
              >
              <LogoBlanco height="20px" />
              </Link>
            </Typography>
          </Box>

          {user && (
            <>
              {roles.includes("admin") && (
                <Box mr={1}>
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/admin"
                    variant="outlined"
                  >
                    Admin
                  </Button>
                </Box>
              )}

              Hola,&nbsp; 
              <span style={{ fontWeight: "bold" }}>  {user.username || this.state.nombre} </span>  
              <IconButton
                color="inherit"
                disabled={performingAction}
                onClick={this.openMenu}
              >
                <UserAvatar user={Object.assign(user, userData)} />
              </IconButton>

              <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={this.closeMenu}
                style={{marginTop: "48px"}}
              >
                {menuItems.map((menuItem, index) => {
                  if (
                    menuItem.hasOwnProperty("condition") &&
                    !menuItem.condition
                  ) {
                    return null;
                  }

                  let component = null;

                  if (menuItem.to) {
                    component = (
                      <MenuItem
                        key={index}
                        component={RouterLink}
                        to={menuItem.to}
                        onClick={this.closeMenu}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          this.closeMenu();

                          menuItem.onClick();
                        }}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  }

                  if (menuItem.divide) {
                    return (
                      <span key={index}>
                        <Divider />

                        {component}
                      </span>
                    );
                  }

                  return component;
                })}
              </Menu>
            </>
          )}

          {!user && false && (
            <ButtonGroup
              color="inherit"
              disabled={performingAction}
              variant="outlined"
            >
              <Button onClick={onSignUpClick}>Sign up</Button>
              <Button onClick={onSignInClick}>Sign in</Button>
            </ButtonGroup>
          )}
        </Toolbar>
      </AppBar>
        );
    }
}

// Eliminar el false de la linea 203 para agregar el sign in y sign up 

Bar.defaultProps = {
    performingAction: false,
};

Bar.propTypes = {
    // Properties
    performingAction: PropTypes.bool.isRequired,
    user: PropTypes.object,
    userData: PropTypes.object,

    // Events
    onAboutClick: PropTypes.func.isRequired,
    onSettingsClick: PropTypes.func.isRequired,
    onSignOutClick: PropTypes.func.isRequired,
};

export default Bar;