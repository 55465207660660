
// TYPES
export const DIRECT_FINANCING = 'DIRECT_FINANCING';
export const EARLY_PAYMENT = 'EARLY_PAYMENT';
export const CONFIRMING = 'CONFIRMING';
export const DF_LABEL = 'Financiamiento Directo';
export const EP_LABEL = 'Pronto Pago';
export const CO_LABEL = 'Confirming';
export const ORDER_TYPES_LABEL = {
  DIRECT_FINANCING: DF_LABEL,
  EARLY_PAYMENT: EP_LABEL,
  CONFIRMING: CO_LABEL,
};

// PAGINATION
export const PAGE_SIZE_INIT = 20;
export const INIT_PAGE = 0;

// PATH
export const ORDER_PATH = {
  root: '/',
  DIRECT_FINANCING: '/orders',
  EARLY_PAYMENT: '/pronto-pago',
  CONFIRMING: '/confirming',
};

// DATES
export const DATE_LOCALE = 'es';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_DB_FORMAT = 'YYYY-MM-DD'; // DEPRECATED DON'T USE
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

// TIMEOUT
export const SEARCH_BAR_TIMEOUT = 500;

// SINGLE STATUS
export const STATUS_IN_REVIEW = 'IN_REVIEW';
export const STATUS_REVIEWED = 'REVIEWED';
export const STATUS_APPEALED = 'APPEALED';
export const STATUS_APPROVED = 'APPROVED';
export const STATUS_APPROVED_FUND = 'APPROVED_FUND';
export const STATUS_TO_DEPOSIT = 'TO_DEPOSIT';
export const STATUS_REPLACED = 'REPLACED';
export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_DEFAULT = 'DEFAULT';
export const STATUS_PAID = 'PAID';
export const STATUS_REJECTED = 'REJECTED';
export const STATUS_COMPLETE = 'COMPLETE';
export const STATUS_PV_APPROVED = 'PV_APPROVED';
export const STATUS_RENEGOTIATION = 'RENEGOTIATION';
export const STATUS_VERIFIED = 'VERIFIED';
export const STATUS_UNVERIFIED = 'UNVERIFIED';
export const STATUS_POST_VERIFIED = 'POST_VERIFIED';
export const STATUS_TRANSFERRED = 'TRANSFERRED';
export const STATUS_CEDE = 'CEDE';
export const STATUS_DELINQUENT = 'DELINQUENT';
export const STATUS_SOFT_DELINQUENCY = 'SOFT_DELINQUENCY';
export const STATUS_HARD_DELINQUENCY = 'HARD_DELINQUENCY';
export const STATUS_HARD_COLLECTION = 'HARD_COLLECTION';
export const STATUS_PENDING = 'PENDING';
export const STATUS_GROUP_CREDITS = 'CREDITS';
export const DEH_DESCRIPTION = 'Acuse Recibo';
export const STATUS_CEDED = 'Cedida';
export const STATUS_CLAIMED = 'Reclamada';
export const STATUS_CANCELED = 'Anulada';
export const STATUS_INCASH_OR_FREE = 'Contado/Gratuito';
export const STATUS_PAUSED = 'PAUSED';

// SETTINGS STATUS
export const STATUS_ORDER_TYPE = 'ORDER';
export const STATUS_INVOICE_TYPE = 'INVOICE';
export const STATUS_CREDIT_TYPE = 'CREDIT';
export const STATUS_FEE_TYPE = 'FEE';
export const ACTION_VIEW_TYPE = 'VIEW';
export const ACTION_CHANGE_TYPE = 'CHANGE';
export const STATUS_FAILED = 'FAILED';
export const STATUS_SUCCESS = 'SUCCESS';
export const IN_REVIEW = 'En Revisión';
export const REVIEWED = 'Revisado';
export const APPEALED = 'Apelado';
export const APPROVED = 'Aprobado';
export const APPROVED_FUND = 'Aprobado Fondo';
export const POST_VERIFIED = 'Post-Verificado';
export const PV_APPROVED = 'PV Aprobado';
export const VERIFIED = 'Verificado';
export const TO_DEPOSIT = 'Por Depositar';
export const ACTIVE = 'Activo';
export const DEFAULT = 'Default';
export const PAID = 'Pagado';
export const COMPLETE = 'Completo';
export const REJECTED = 'Rechazado';
export const REPLACED = 'Reemplazado';
export const TRANSFERRED = 'Transferido';
export const SOFT_DELINQUENCY = 'Mora Blanda';
export const HARD_DELINQUENCY = 'Mora Dura';
export const DELINQUENT = 'Mora';
export const HARD_COLLECTION = 'Estado Crítico';
export const CEDE = 'Cedido';

export const CRITICAL_INVOICES_STATUS_GROUP = [
  'IN_REVIEW',
  'APPROVED',
  'TRANSFERRED',
];

export const DEFAULT_STATUS = [
  // IN REVIEW
  {
    key: STATUS_IN_REVIEW,
    label: 'En Revisión',
    group: STATUS_IN_REVIEW,
  },
  { key: STATUS_REVIEWED, label: 'Revisado', group: STATUS_IN_REVIEW },
  {
    key: STATUS_APPEALED,
    label: 'Apelable',
    group: STATUS_IN_REVIEW,
  },
  { key: STATUS_APPROVED, label: 'Aprobado', group: STATUS_IN_REVIEW },
  // APPROVED
  {
    key: STATUS_APPROVED_FUND,
    label: 'Aprobado Fondo',
    group: STATUS_APPROVED,
  },
  {
    key: STATUS_TO_DEPOSIT,
    label: 'Por Depositar',
    group: STATUS_APPROVED,
  },
  // TRANSFERRED
  { key: STATUS_ACTIVE, label: 'Activo', group: STATUS_TRANSFERRED },
  { key: STATUS_DEFAULT, label: 'Default', group: STATUS_TRANSFERRED },
  // PAID
  { key: STATUS_PAID, label: 'Pagado', group: STATUS_PAID },
  { key: STATUS_COMPLETE, label: 'Completo', group: STATUS_PAID },
  // REJECTED
  {
    key: STATUS_REJECTED,
    label: 'Rechazado',
    group: STATUS_REJECTED,
  },
];
export const STATUSES_VERIFIED = [
  {
    key: STATUS_POST_VERIFIED,
    label: 'Post-Verificado',
  },
  { key: STATUS_VERIFIED, label: 'Verificado' },
  { key: STATUS_UNVERIFIED, label: 'Sin verificar' },
];

export const INVOICE_STATUSES_GROUP = {
  [STATUS_IN_REVIEW]: {
    key: STATUS_IN_REVIEW,
    label: 'En revisión',
  },
  [STATUS_APPROVED]: {
    key: STATUS_APPROVED,
    label: 'Aprobado',
  },

  [STATUS_TRANSFERRED]: {
    key: STATUS_TRANSFERRED,
    label: 'Transferido',
  },
  [STATUS_PAID]: {
    key: STATUS_PAID,
    label: 'Pagado',
  },
  [STATUS_REJECTED]: {
    key: STATUS_REJECTED,
    label: 'Rechazado',
  },
  [STATUS_REPLACED]: {
    key: STATUS_REPLACED,
    label: 'Reemplazado',
  },
};

export const ORDER_STATUSES_GROUP = {
  [STATUS_IN_REVIEW]: {
    key: STATUS_IN_REVIEW,
    label: 'En revisión',
  },
  [STATUS_APPROVED]: {
    key: STATUS_APPROVED,
    label: 'Aprobado',
  },

  [STATUS_TRANSFERRED]: {
    key: STATUS_TRANSFERRED,
    label: 'Transferido',
  },
  [STATUS_PAID]: {
    key: STATUS_PAID,
    label: 'Pagado',
  },
  [STATUS_REJECTED]: {
    key: STATUS_REJECTED,
    label: 'Rechazado',
  },
  [STATUS_REPLACED]: {
    key: STATUS_REPLACED,
    label: 'Reemplazado',
  },
  [STATUS_RENEGOTIATION]: {
    key: STATUS_RENEGOTIATION,
    label: 'Renegociado',
  },
};

export const STATUSES = {
  factura: [
    ...DEFAULT_STATUS,
    {
      key: STATUS_CEDE,
      label: 'Cedido',
      group: STATUS_APPROVED,
    },
    {
      key: STATUS_SOFT_DELINQUENCY,
      label: 'Mora Blanda',
      group: STATUS_TRANSFERRED,
    },
    {
      key: STATUS_HARD_DELINQUENCY,
      label: 'Mora Dura',
      group: STATUS_TRANSFERRED,
    },
    { key: STATUS_DELINQUENT, label: 'Mora', group: STATUS_TRANSFERRED },
    {
      key: STATUS_HARD_COLLECTION,
      label: 'Estado Crítico',
      group: STATUS_TRANSFERRED,
    },
    {
      key: STATUS_REPLACED,
      label: 'Reemplazado',
      group: STATUS_REPLACED,
    },
  ],
  facturasBulk: [
    ...DEFAULT_STATUS,
    {
      key: STATUS_CEDE,
      label: 'Cedido',
      group: STATUS_APPROVED,
    },
    {
      key: STATUS_SOFT_DELINQUENCY,
      label: 'Mora Blanda',
      group: STATUS_TRANSFERRED,
    },
    {
      key: STATUS_HARD_DELINQUENCY,
      label: 'Mora Dura',
      group: STATUS_TRANSFERRED,
    },
    { key: STATUS_DELINQUENT, label: 'Mora', group: STATUS_TRANSFERRED },
    {
      key: STATUS_HARD_COLLECTION,
      label: 'Estado Crítico',
      group: STATUS_TRANSFERRED,
    },
  ],
  order: [
    ...DEFAULT_STATUS,
    {
      key: STATUS_PV_APPROVED,
      label: 'PV Aprobado',
      group: STATUS_APPROVED,
    },
    {
      key: STATUS_RENEGOTIATION,
      label: 'Renegociado',
      group: STATUS_REJECTED,
    },
  ],
  fee: [
    {
      key: STATUS_ACTIVE,
      label: 'Activo',
      group: STATUS_GROUP_CREDITS,
    },
    {
      key: STATUS_PAID,
      label: 'Pagado',
      group: STATUS_GROUP_CREDITS,
    },
    {
      key: STATUS_REJECTED,
      label: 'Rechazado',
      group: STATUS_GROUP_CREDITS,
    },
  ],
  credit: [
    {
      key: STATUS_IN_REVIEW,
      label: 'En revisión',
      group: STATUS_GROUP_CREDITS,
    },
    {
      key: STATUS_REVIEWED,
      label: 'Revisado',
      group: STATUS_GROUP_CREDITS,
    },
    { key: STATUS_APPROVED, label: 'Aprobado', group: STATUS_GROUP_CREDITS },
    {
      key: STATUS_APPROVED_FUND,
      label: 'Aprobado Fondo',
      group: STATUS_GROUP_CREDITS,
    },
    {
      key: STATUS_PENDING,
      label: 'Pendiente',
      group: STATUS_GROUP_CREDITS,
    },
    {
      key: STATUS_ACTIVE,
      label: 'Activo',
      group: STATUS_GROUP_CREDITS,
    },
    {
      key: STATUS_PAID,
      label: 'Pagado',
      group: STATUS_GROUP_CREDITS,
    },
    { key: STATUS_COMPLETE, label: 'Completo', group: STATUS_GROUP_CREDITS },
    {
      key: STATUS_REJECTED,
      label: 'Rechazado',
      group: STATUS_GROUP_CREDITS,
    },
  ],
  checkInvoice: STATUSES_VERIFIED,
};
export const SORTED_STATUS_GROUP = [
  STATUS_IN_REVIEW,
  STATUS_APPROVED,
  STATUS_TRANSFERRED,
  STATUS_PAID,
  STATUS_REJECTED,
];

// REASONS
export const REASONS = {
  HARD_COLLECTION: [
    { key: 'CREDIT_NOTE', label: 'Nota de Crédito' },
    { key: 'DEFAULT', label: 'Mora' },
    { key: 'REPORTED_IN_DICOM', label: 'Reportado en Dicom' },
    { key: 'REJECTED', label: 'Rechazada' },
    { key: 'CANCELED', label: 'Anulada' },
    { key: 'UNCONTACTABLE_PAYER', label: 'Pagador Incontactable' },
    { key: 'PAID_TO_CLIENT', label: 'Pagado a Cliente' },
    { key: 'CLAIMED_WITHOUT_MERIT', label: 'Reclamada Sin merito' },
    { key: 'CLAIMED', label: 'Reclamada' },
    { key: 'DOES_NOT_ACCEPT_FACTORING', label: 'No Acepta Factoring' },
    { key: 'PAYMENT_VV_CLIENT', label: 'Pago VV Cliente' },
    { key: 'INVOICE_IN_PAYMENT_PLAN', label: 'Factura en plan de pago' },
    {
      key: 'PAYER_WITH_PAYMENT_PROBLEMS',
      label: 'Pagador con problemas de pago',
    },
    {
      key: 'INVOICE_WITHOUT_ASSIGMENT',
      label: 'Factura sin cesión',
    },
  ],
  VERIFIED: [
    {
      key: 'PRODUCTS_AND_OR_SERVICES_OK',
      label: 'Productos y/o servicios OK',
    },
    { key: 'ACCOUNTED', label: 'Contabilizado' },
    { key: 'WITH_PAYMENT_DATE', label: 'Con fecha de pago' },
    { key: 'BY_PHONE', label: 'Por teléfono' },
    { key: 'FOR_IRREVOCABILITY', label: 'Por irrevocabilidad' },
    {
      key: 'BY_ELECTRONIC_ACKNOWLEDGMENT',
      label: 'Por acuse de recibo electrónico',
    },
    { key: 'PROTOCOL_PAYER', label: 'Por protocolo pagador' },
  ],
  POST_VERIFIED: [
    { key: 'RECURRING_RELATIONSHIP', label: 'Relación recurrente' },
    {
      key: 'NOT_ANSWER_SENT_MAIL',
      label: 'No contestan correo enviado',
    },
  ],
  REJECTED: [
    { key: 'HIGH_RISK', label: 'Riesgo Alto' },
    {
      key: 'INVOICE_IN_PARALLEL_OP',
      label: 'Factura en op paralela',
    },
    {
      key: 'LOWER_RATE_WITH_MERIT_0_2_TO_0_4',
      label: 'Menor tasa con merito 0,2 a 0,4',
    },
    {
      key: 'LOWER_RATE_WITH_MERIT_0_41_TO_0_6',
      label: 'Menor tasa con merito 0,41 a 0,6',
    },
    {
      key: 'LOWER_RATE_WITH_MERIT_0_61_TO_0_8',
      label: 'Menor tasa con merito 0,61 a 0,8',
    },
    {
      key: 'LOWER_RATE_WITHOUT_MERIT_0_3_TO_0_5',
      label: 'Menor tasa sin merito 0,3 a 0,5',
    },
    {
      key: 'LOWER_RATE_WITHOUT_MERIT_0_51_TO_0_7',
      label: 'Menor tasa sin merito 0,51 a 0,7',
    },
    {
      key: 'INVOICES_IN_PAF_OPERATION',
      label: 'Facturas contra PAF',
    },
    {
      key: 'NOT_AVAILABLE_FUND',
      label: 'Sin fondo de salida',
    },
    {
      key: 'NO_CUSTOMER_CONTACT',
      label: 'Sin contacto con el cliente',
    },
    {
      key: 'RELATIONSHIP_WITH_ANOTHER_INSTITUTION',
      label: 'Relación con otra institución',
    },
    {
      key: 'WILL_BE_PAID_CLIENT',
      label: 'Se pagará a emisor',
    },
    {
      key: 'CANCELED',
      label: 'Anulada',
    },
    {
      key: 'REJECTED',
      label: 'Rechazada',
    },
    {
      key: 'CREDIT_NOTE',
      label: 'Nota de crédito',
    },
    {
      key: 'PAYER_CONCENTRATION_LIMIT',
      label: 'Límite concentración pagador',
    },
    {
      key: 'CUSTOMER_CONCENTRATION_LIMIT',
      label: 'Límite concentración cliente',
    },
    {
      key: 'PAYER_CHANGE',
      label: 'Cambio de pagador',
    },
  ],
};

// COLLECTION COMMENTS
export const COMMENT_TYPES = [
  {
    type: 'Fondo',
    style: {
      background: '#A8EFBB',
      color: '#119B41',
      font: 'Bold 11px/33px Muli',
    },
  },
  {
    type: 'Pagador',
    style: {
      background: '#D3DDFD',
      color: '#1A49E7',
      font: 'Bold 11px/33px Muli',
    },
  },
  {
    type: 'Xepelin',
    style: {
      background: '#E0D3FD',
      color: '#900CF9',
      font: 'Bold 11px/33px Muli',
    },
  },
  {
    type: 'Cliente',
    style: {
      background: '#FFD2AC',
      color: '#E66404',
      font: 'Bold 11px/33px Muli',
    },
  },
];

// EMAILS
export const COLLECTION_EMAIL_ICONS = [
  
];

// COLORS  ===NO USAR===
export const WHITE_BORDER_COLOR = '#EEE';
export const SECOND_WHITE_COLOR = '#F3F2F5';
export const GRAY_COLOR = '#575360';
export const WHITE_PURE_COLOR = '#FFFF';
export const GRAY_WHITE_COLOR = '#eff0f1';
export const RED_ROW_COLOR = '#ff00000d';
export const BLUE_ROW_COLOR = '#F5F7FF';
export const GRAY_PURPLE_COLOR = '#6C6776';
export const MANATEE_COLOR = '#A8A4B3';
export const GRAY_BORDER_COLOR = '#E4E1E9';

// TYPES STATUS CHANGE DIALOG
export const ITEM_TYPE_CHECK_INVOICE = 'checkInvoice';
export const ITEM_TYPE_ORDER = 'order';
export const ITEM_TYPE_INVOICE = 'factura';
export const ITEM_TYPE_BULK_INVOICE = 'facturasBulk';
export const ITEM_TYPE_CREDIT = 'credit';
export const ITEM_TYPE_NEW_CREDIT = 'newCredit';
export const ITEM_TYPE_FEE = 'fee';
export const DIALOG_TYPES = {
  ACCOUNTING: 'accounting',
  INVOICE: 'invoice',
  BULK_FUND_INVOICE: 'fundInvoice',
  FACTURASBULK: ITEM_TYPE_BULK_INVOICE,
  ORDERCREDIT: 'orderCredit',
  ORDER: ITEM_TYPE_ORDER,
  TRANSFERENCIA: 'transferencia',
};

// SETTINGS
export const DECIMAL_PRECISION = 2;
export const MAX_DECIMALS = 4;
export const DAYS_MONTH = 30;

// METABASE URL
export const METABASE_URL = 'https://analytics.xepelin.com';

// TYPE FILES
export const CONTRACT = 'CONTRACT';
export const CONTRACT_LABEL = 'Contrato';
export const LEGAL_REPORT = 'LEGAL_REPORT';
export const LEGAL_REPORT_LABEL = 'Informe legal';
export const COMPLIANCE = 'COMPLIANCE';
export const COMPLIANCE_DOWNLOAD = 'COMPLIANCE_DOWNLOAD';
export const COMPLIANCE_LABEL = 'Compliance';
export const SUPER_DEBT = 'Deuda Financiera';

export const TYPE_FILES = {
  [CONTRACT]: {
    label: CONTRACT_LABEL,
  },
  [LEGAL_REPORT]: {
    label: LEGAL_REPORT_LABEL,
  },
  [COMPLIANCE]: {
    label: COMPLIANCE_LABEL,
  },
};

// VARIANT NOTIFICATIONS
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';

// CESSIONARIES
export const XCAPITAL_CESSIONARY = 'X CAPITAL SPA';

// MESSAGES
export const NO_INFO = 'Sin Información';
export const NO_DATA = 'No hay información disponible';
export const LOADING_INVOICES = 'Cargando Facturas...';
export const FIELD_REQUIRED = 'Campo requerido';
export const INVALID_EMAIL = 'Email inválido';
export const EMAILS_PLACEHOLDER = 'Ingrese los emails...';
export const MIN_LENGTH = 'Debe tener un mínimo de 6 carácteres';
export const MATCH_PASSWORD = 'Debe ser igual a la password ingresada';
export const UNKNOWN_ERROR = 'Error desconocido';
export const CHECKBOX_MESSAGE = 'Confirmo que deseo continuar';
export const LABEL_NO_ORDERS_REPLACE = 'No hay ordenes para reemplazar';
export const LABEL_MUST_SELECT_ORDER = 'Debe seleccionar una orden';
export const LABEL_TOTAL_AMOUNT_CREDIT_NOTES = 'Monto total notas de Crédito';
export const ADD_PAYER = 'Pagador Agregado!';
export const ERROR_ADD_PAYER = 'Error al registrar pagador';
export const ERROR_DELETE_CONTACT_PAYER = 'Error al eliminar un contacto';
export const ERROR_CREATE_CONTACT_PAYER = 'Error al crear un contacto';
export const ERROR_EDIT_CONTACT_PAYER = 'Error al editar un contacto';
export const ADD_FEES = 'Fees Guardado';
export const RESET_FEES = 'Fees Reseteado';
export const ERROR_ADD = 'Error al Guardar';
export const ERROR_RESET = 'Error al Actualizar';
export const ERROR_ITEM_SELECTED = 'Debe seleccionar al menos un rol';
export const MESSAGE_FIELD_NOT_EMPTY = 'El campo no puede estar vacío';

export const INVOICE_DAYS_PAST = '> 65 días';

// VALIDATIONS
export const RUT_VALIDATION = 'Rut inválido';
export const IS_POSITIVE_VALIDATION = 'Valor debe ser mayor a 0';
export const IS_POSITIVE_OR_ZERO_VALIDATION =
  'Valor debe ser mayor o igual a 0';
export const MAX_DECIMALS_VALIDATION = 'Valor puede contener 4 decimales';
export const VALIDATOR_REQUIRED = 'required';
export const VALIDATOR_NOT_EMPTY = 'notEmpty';
export const VALIDATOR_IS_EQUAL = 'isEqual';
export const VALIDATOR_MIN_LENGTH = 'minLength';
export const VALIDATOR_RUT = 'isValidRut';
export const VALIDATOR_POSITIVE = 'isPositive';
export const VALIDATOR_MAXDECIMALS = 'maxDecimals';
export const VALIDATOR_EMAIL = 'isEmail';
export const VALIDATOR_ITEM_SELECTED = 'itemSelected';
export const VALIDATOR_GREATER_OR_EQUAL_ZERO = 'IsGreaterOrEqualToZero';

// ROLES
export const ROLE_COLLECTION = 'ROLE_COLLECTION';

// FILTERS
export const BLOCKED_FILTERS = [
  'status',
  'Business.totalOrdersTransferred',
  'montoTransferencia',
  'intereses',
  'tasaBase',
  'fechas',
  'detMntTotal',
  'assignor',
  'transfer',
  'interest',
  'baseRate',
];
export const VERIFICATION_MENU_FILTER = ['Si', 'No'];
export const MORA_RANGE_FILTER = ['Vigente', '0-30', '30-60', '60-90', '>90'];

export const TGR = 'TGR';
export const PREVIRED = 'PREVIRED';

export const ADMIN_ROLE = 'admin';
export const COMMERCIAL_ROLE = 'comercial';
export const COMMERCIAL_SUPERVISOR_ROLE = 'comercialSupervisor';
export const RISK_ROLE = 'riesgo';
export const RISK_SUPERVISOR_ROLE = 'riesgoSupervisor';
export const OPERATIONS_ROLE = 'operaciones';
export const OPERATIONS_SUPERVISOR_ROLE = 'operacionesSupervisor';
export const TREASURY_ROLE = 'tesoreria';
export const TREASURY_SUPERVISOR_ROLE = 'tesoreriaSupervisor';
export const COLLECTION_ROLE = 'cobranza';
export const COLLECTION_SUPERVISOR_ROLE = 'cobranzaSupervisor';
export const COUNTER_ROLE = 'contabilidad';
export const VIEWER_ROLE = 'lector';

// SECTIONS
export const SECTIONS_ARRAY = [
  { key: 'orders', label: 'operaciones' },
  { key: 'invoices', label: 'facturas' },
  { key: 'credits', label: 'creditos' },
  { key: 'business', label: 'empresa' },
  { key: 'collection', label: 'cobranza' },
  { key: 'criticalStates', label: 'estados críticos' },
  { key: 'funds', label: 'fondos' },
  { key: 'blocklist', label: 'blocklist' },
  { key: 'payers', label: 'pagadores' },
  { key: 'agreements', label: 'convenios' },
  { key: 'users', label: 'usuarios' },
  { key: 'wallets', label: 'wallets' },
  { key: 'stakeholders', label: 'stakeholders' },
];
export const STATUS_PERMISSION_ARRAY = {
  ORDER: {
    label: 'ordenes',
    statuses: [
      STATUS_IN_REVIEW,
      STATUS_REVIEWED,
      STATUS_APPEALED,
      STATUS_APPROVED,
      STATUS_APPROVED_FUND,
      STATUS_TO_DEPOSIT,
      STATUS_ACTIVE,
      STATUS_DEFAULT,
      STATUS_PAID,
      STATUS_REJECTED,
      STATUS_COMPLETE,
      STATUS_PV_APPROVED,
      STATUS_RENEGOTIATION,
    ],
  },
  INVOICE: {
    label: 'facturas',
    statuses: [
      STATUS_IN_REVIEW,
      STATUS_REVIEWED,
      STATUS_APPEALED,
      STATUS_APPROVED,
      STATUS_APPROVED_FUND,
      STATUS_TO_DEPOSIT,
      STATUS_ACTIVE,
      STATUS_DEFAULT,
      STATUS_PAID,
      STATUS_REJECTED,
      STATUS_COMPLETE,
      STATUS_SOFT_DELINQUENCY,
      STATUS_HARD_DELINQUENCY,
      STATUS_DELINQUENT,
      STATUS_HARD_COLLECTION,
      STATUS_REPLACED,
      STATUS_CEDE,
    ],
  },
  CREDIT: {
    label: 'creditos',
    statuses: [
      STATUS_IN_REVIEW,
      STATUS_REVIEWED,
      STATUS_APPROVED,
      STATUS_APPROVED_FUND,
      STATUS_PENDING,
      STATUS_ACTIVE,
      STATUS_PAID,
      STATUS_COMPLETE,
      STATUS_REJECTED,
    ],
  },
  FEE: {
    label: 'cuotas',
    statuses: [STATUS_ACTIVE, STATUS_PAID, STATUS_REJECTED],
  },
};

// KEY_CODES
export const KEY_CODES = {
  ESCAPE: 27,
  DELETE: 46,
  DOWN_ARROW: 40,
};

// TITLES
export const TITLE_INVOICE_CHECK = 'Verificar factura';
export const TITLE_DIALOG_CHANGE_STATUS =
  'Selecciona el nuevo estado, confirma y guarda';
export const BULK_LOAD_ACCOUNTING = 'Carga masiva Contabilidad';
export const MASS_UPDATE_INVOICES = 'Actualización masiva de Facturas';
export const MASS_UPDATE_INVOICES_FUNDS = 'Actualización masiva de Fondos';
export const SEND_SIMULATIN_EMAIL = 'Enviar simulación por email';
export const ENTER_NECESSARY_DATA = 'Ingrese los datos necesarios';
export const ENTER_DISCOUNT_AMOUNT_CONFIRM_AND_SAVE =
  'Ingrese un monto y razón de descuento, confirme y guarde';
export const TITLES_NOT_FOUND = {
  CONTENT_NOT_EXIST: 'El contenido que buscas no existe',
  CHECK_IF_LINK_IS_CORRECT:
    'Verifica si el enlace es correcto o busca nuevamente en el listado de operaciones.',
};
// COUNTRIES
export const COUNTRIES_SETTING_LIST = ['CL', 'MX', 'pe', 'co', 'ar', 'br'];
export const COUNTRIES_PHONE_LIST = ['cl', 'cl', 'pe', 'co', 'ar', 'br'];
export const DEFAULT_PHONE_COUNTRY = 'cl';
export const DEFAULT_COUNTRY = 'CL';
export const ACTIVE_COUNTRIES_LIST = [
  {
    key: 'CL',
    name: 'Chile',
    currency: 'CLP',
  },
  {
    key: 'MX',
    name: 'México',
    currency: 'MXN',
  },
];

// CONTACT TYPE
export const CONTACT_TYPE = {
  CONFIRMATION: 'CONFIRMATION',
  PAYMENT: 'PAYMENT',
};
export const DISCOUNT_REASONS = [
  {
    key: 'PAGO_FACTURA',
    label: 'Pago de factura',
  },
  {
    key: 'PAGO_CREDITO',
    label: 'Pago de credito',
  },
  {
    key: 'PAGO_CXP',
    label: 'Pago de CxP',
  },
  { key: 'CAMBIO_FACTURA', label: 'Cambio de factura' },
  {
    key: 'ERROR_GIRO',
    label: 'Error en el giro',
  },
  {
    key: 'ANULACION',
    label: 'Anulación',
  },
  {
    key: 'NOTA_CREDITO',
    label: 'Nota de Crédito',
  },
];

export const SIN_DESCUENTO = 'SIN_DESCUENTO';

// ACCOUNT TYPE TRANSFORM

export const TRANSFORMED_ACCOUNT_TYPES = {
  CUENTA_CORRIENTE: 'Cuenta corriente',
  CUENTA_VISTA: 'Cuenta vista',
  CUENTA_AHORRO: 'Cuenta ahorro',
  CUENTA_CLABE: 'Cuenta CLABE',
};

// ACCOUNT TYPES

export const ACCOUNT_TYPES = {
  CL: [
    { value: 'CUENTA_CORRIENTE', label: 'Cuenta corriente' },
    { value: 'CUENTA_VISTA', label: 'Cuenta vista' },
    { value: 'CUENTA_AHORRO', label: 'Cuenta ahorro' },
  ],
  MX: [{ value: 'CUENTA_CLABE', label: 'Cuenta CLABE' }],
};

// WALLET

export const WALLET_STATUS = [
  'REQUESTED',
  'TO_DEPOSIT',
  'WALLET_ACTIVE',
  'WALLET_INACTIVE',
];

export const WALLET_STATUS_SELECT = [
  { value: 'REQUESTED', label: 'Solicitado' },
  { value: 'TO_DEPOSIT', label: 'Por depositar' },
  { value: 'WALLET_ACTIVE', label: 'Activo' },
  { value: 'WALLET_INACTIVE', label: 'Inactivo' },
];

export const WALLET_TRANSACTION_TYPES = [
  { value: 'EXCEDENTE', label: 'Excedentes' },
  { value: 'PAGO_EXCEDENTE', label: 'Pago de excedentes' },
  { value: 'CXC', label: 'Cuentas por cobrar' },
  { value: 'PAGO_CXC', label: 'Pago de cuentas por cobrar' },
  { value: 'DEUDA_PENDIENTE', label: 'Deuda pendiente' },
  { value: 'PAGO_DEUDA_PENDIENTE', label: 'Pago de deuda pendiente' },
];

export const WALLET_REQUEST_COMMENTS = [
  'Facturas con Notas de crédito',
  'Factura pagada a cliente',
  'Cliente debe intereses',
  'Deuda Pendiente',
];

export const WALLET_REQUEST_STATUS = [
  { value: 'PENDING', label: 'Vigente' },
  { value: 'ACCOMPLISHED', label: 'Realizado' },
  { value: 'REJECTED', label: 'Rechazado' },
];

export const WALLET_ACTIVITIES = {
  EXCEDENTE: 'Excedentes',
  PAGO_EXCEDENTE: 'Pago de excedentes',
  DEUDA_PENDIENTE: 'Deuda pendiente',
  PAGO_DEUDA_PENDIENTE: 'Pago de deuda pendiente',
  CXC: 'Cuentas por cobrar',
  PAGO_CXC: 'Pago de cuentas por cobrar',
};

export const WALLET_ACCOUNT_TYPES = {
  BUSINESS: 'Pyme',
  FUND: 'Fondo',
  PAYER: 'Corporativo',
};

export const REQUEST_STATUSES = {
  ACCOMPLISHED: 'Realizada',
  REJECTED: 'Rechazada',
  PENDING: 'Vigente',
};

// TRANSLATE
export const TRANSLATOR = {
  identifier: {
    CL: 'Rut',
    MX: 'RFC',
  },
  debtservice: {
    CL: 'Dicom',
    MX: 'Buró de crédito',
  },
  carpetaTributaria: {
    CL: 'Carpeta Tributaria',
    MX: 'Declaración fiscal',
  },
  messageErrorIdentifier: {
    CL: 'Rut inválido',
    MX: 'RFC inválido',
  },
};

export const TRANSACTION_AMOUNT_TYPES = [
  { value: 'CARGO', label: 'Cargo' },
  { value: 'ABONO', label: 'Abono' },
];

export const COUNTRY_CODE_MX = 'MX';
export const COUNTRY_CODE_CL = 'CL';

export const AVAILABLE_COUNTRIES = [COUNTRY_CODE_CL, COUNTRY_CODE_MX];

// STEPPER VARIANT

export const STEPPER_PRIMARY = 'primary';
export const STEPPER_SUCCESS = 'success';

// CREDITS

export const CREDIT_PERIODS = {
  BIANNUAL: 'Semestral',
  QUARTERLY: 'Trimestral',
  MONTHLY: 'Mensual',
  BIWEEKLY: 'Quincenal',
};

export const CREDIT_TYPES = {
  NORMAL: 'Normal',
  RENEGOTIATION: 'Refinanciamiento',
};

// CIVILSTATUS
export const CIVILSTATUS_CL = [
  { value: 'Soltero', label: 'Soltero/a' },
  { value: 'Casado', label: 'Casado/a' },
  { value: 'Casado', label: 'Casado/a con sociedad conyugal' },
  { value: 'Viudo', label: 'Viudo/a' },
  { value: 'Divorciado', label: 'Divorciado/a' },
  { value: 'Separado', label: 'Separado/a' },
];

export const CIVILSTATUS_MX = [
  { value: 'Soltero/a', label: 'Soltero/a' },
  { value: 'Casado/a', label: 'Casado/a' },
  { value: 'Viudo/a', label: 'Viudo/a' },
  {
    value: 'Separación en Proceso Judicial',
    label: 'Separación en Proceso Judicial',
  },
  { value: 'Divorciado/a', label: 'Divorciado/a' },
  { value: 'Concubinato', label: 'Concubinato' },
];

export const INVOICE_STATUS = {
  IN_REVIEW,
  REVIEWED,
  APPEALED,
  APPROVED,
  APPROVED_FUND,
  TO_DEPOSIT,
  ACTIVE,
  DEFAULT,
  PAID,
  REJECTED,
  COMPLETE,
  SOFT_DELINQUENCY,
  HARD_DELINQUENCY,
  DELINQUENT,
  HARD_COLLECTION,
  REPLACED,
  CEDE,
};

// SEARCHBAR COMPONENT
export const SEARCHBAR_TIMEOUT = 1400;
