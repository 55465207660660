import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Button, 
  Stack
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { BorderColor, Close as CloseIcon } from "@material-ui/icons";

import { auth, firestore } from '../../firebase';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

function AboutDialog(props) {
  const classes = useStyles();
  const dialogProps = props.dialogProps;
  const user = props.user;
  const version = process.env.REACT_APP_VERSION;
  const [perfiles, setPerfiles] = useState([]);
  const [perfilSelected, setPerfilSelected] = useState('');

  useEffect(() => {
    if (user) {
      const currentUser = auth.currentUser;
      const uid = currentUser.uid;
      if (perfilSelected){
        firestore.collection("users").doc(uid).update({perfilSelected: perfilSelected});   
      }
    }
  }, [perfilSelected]);

  useEffect(() => {
    if (user) {
      const currentUser = auth.currentUser;
      setPerfiles(currentUser["perfiles"])
      setPerfilSelected(currentUser["perfilActual"])
    }
  }, []);


  if (!user && !version) {
    return null;
  }



  return (
    <Dialog fullWidth maxWidth="xs" {...dialogProps}>
      <DialogTitle disableTypography>
        <Typography variant="h6">
          Seleccionar perfil 
        </Typography>

        <Tooltip title="Close">
          <IconButton
            className={classes.closeButton}
            onClick={dialogProps.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <List disablePadding>
          {version && (
            <div style={{paddingTop: "25px"}}>
              {perfiles.map( (perf) => {
                return (
                <div style={{paddingBottom: "25px"}}> 
                    <Button  
                      variant="outlined" 
                      style= {(perf == perfilSelected)? {color:"#8F33FF", BorderColor:"#8F33FF"} : {} }
                      onClick={() => {
                        setPerfilSelected(perf); 
                        dialogProps.onClose()}
                     } >
                      {perf}
                    </Button>
                </div>)
              })}           
            </div>   
           )}

        </List>
      </DialogContent>
    </Dialog>
  );
}

AboutDialog.propTypes = {
  dialogProps: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default AboutDialog;
